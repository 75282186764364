import React from "react";
import classNames from "classnames";

import { GlobeAltIcon, AcademicCapIcon } from "@heroicons/react/solid";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import data from "../../data";

dayjs.extend(localizedFormat);

export default function Example() {
  return (
    <div className="card max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow">
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Education
          </h3>
        </div>

        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          {data.education.map((school) => (
            <React.Fragment key={school.name}>
              <div className="md:flex md:items-center md:justify-between md:space-x-5">
                <div className="flex items-start space-x-5">
                  <div className="flex-shrink-0">
                    <div className="relative">
                      <img
                        className="h-16 w-16 rounded-full"
                        src={school.logo}
                        alt=""
                      />
                      <span
                        className="absolute inset-0 shadow-inner rounded-full"
                        aria-hidden="true"
                      />
                    </div>
                  </div>

                  <div className="pt-1.5">
                    <h1 className="text-2xl font-bold text-gray-900">
                      {school.name}
                    </h1>
                    <p className="text-sm font-medium text-gray-500">
                      {school.date
                        ? dayjs(school.date).format("LL")
                        : `${dayjs(school.dateFrom).format("LL")} - ${dayjs(
                            school.dateTo
                          ).format("LL")}`}
                    </p>
                  </div>
                </div>
                <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                    onClick={() => window.open(`${school.url}`, "blank")}
                  >
                    <GlobeAltIcon
                      className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span>Website</span>
                  </button>
                </div>
              </div>
              <div className="timeline flow-root">
                <ul className="-mb-8">
                  {school.degrees.map((degree, degreeIdx) => (
                    <li key={degree.degree}>
                      <div className="relative pb-8">
                        {degreeIdx !== school.degrees.length - 1 ? (
                          <span
                            className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                            aria-hidden="true"
                          />
                        ) : null}
                        <div className="relative flex space-x-3">
                          <div>
                            <span
                              className={classNames(
                                "bg-gray-400",
                                "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                              )}
                            >
                              <AcademicCapIcon
                                className="h-5 w-5 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                          <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                            <div className="min-w-0 flex-1">
                              <div>
                                <p className="text-sm text-gray-500">
                                  {`${degree.degree} -- GPA: ${degree.gpa}`}
                                </p>
                              </div>
                              <div className="text-xs whitespace-nowrap text-gray-500">
                                {dayjs(degree.date).format("LL")}
                              </div>
                              <div className="mt-2 text-sm text-gray-700">
                                <ul className="show-bullets">
                                  {degree.experiences.map((experience) => (
                                    <li key={experience}>{experience}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

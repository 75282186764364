import avatar from "./logos/headshot.jpeg";
import backgroundImage from "./logos/background.jpeg";
import USU from "./logos/USU.png";
// import DHS from "./logos/DHS.jpg";
import Adobe from "./logos/Adobe.png";
import XW from "./logos/XW.jpg";
import VS from "./logos/VS.png";
import RRD from "./logos/RRD.png";
import GB from "./logos/GB.jpg";
import LDS from "./logos/LDS.jpg";
import FBLA from "./logos/FBLA.png";
// import BSA from "./logos/BSA.jpg";
import Mensa from "./logos/Mensa.png";

const experience = {
  name: "Richie Preece",
  email: "richie.preece@gmail.com",
  phone: "8014367086‬",
  title: "Senior Software Engineer",
  location: "Salt Lake City, Utah",
  linkedin: "https://www.linkedin.com/in/richie-preece/",
  avatar,
  backgroundImage,
  objective:
    "Experienced Engineer with a demonstrated history of working in the computer software industry. Skilled in Java, Javascript, PHP, Unix, Bash, and Databases. Strong consulting professional with a Master of Science (M.S.) focused in Computer Science from Utah State University.",
  education: [
    {
      dateFrom: "August 2006",
      dateTo: "August 2014",
      name: "Utah State University",
      location: "Logan, Ut",
      url: "https://usu.edu",
      logo: USU,
      degrees: [
        {
          degree: "M.S. Degree, Computer Science",
          date: "04/24/2014",
          gpa: 3.57,
          experiences: [
            "Focused on Human-Computer interaction using Android",
            "Studied topics on Databases, Security, Data Structures, and Algorithms",
          ],
        },
        {
          degree: "B.S. Degree, Computer Science",
          date: "12/15/2012",
          gpa: 3.3,
          experiences: [
            "Major Concentration: Software Development",
            "Programmed in C, C++, Java, C#, PostScript, Scheme, JavaScript, Assembly, Haskell, Prolog, PHP",
            "Experienced in Data Structures, Algorithms, Object Oriented Programming, Databases, Security, Software Engineering, Operating Systems, UML",
            "Led a team, as Project Manager, to create a system capable of running a resaurant",
          ],
        },
      ],
    },
    // {
    //   dateFrom: "August 2003",
    //   dateTo: "June 2006",
    //   name: "Davis High School",
    //   location: "Kaysville, Ut",
    //   url: "http://www.davis.k12.ut.us/708/site/default.asp",
    //   logo: DHS,
    //   degrees: [
    //     {
    //       degree: "High School Diploma",
    //       date: "06/15/2006",
    //       gpa: 3.52,
    //       experiences: [
    //         "Experienced in Microsoft Office Suite, HTML, Adobe Products",
    //         "Participated in the Future Business Leaders of America as a chapter officer and webmaster",
    //       ],
    //     },
    //   ],
    // },
  ],
  work: [
    {
      dateFrom: "06/22/2015",
      dateTo: "current",
      company: "Adobe Systems, Inc",
      location: "Lehi, Ut",
      url: "https://adobe.com",
      logo: Adobe,
      positions: [
        {
          dateFrom: "01/01/2021",
          dateTo: "current",
          position: "Senior Software Engineer",
          experiences: [
            "Used React.js to build industry-leading enterprise applications",
            "Led projects improving customer satisfaction and retention",
            "Created new developer tools to aid in speed of software development",
            "Improved testing and alerting to keep availability at a maximum",
          ],
        },
        {
          dateFrom: "06/15/2019",
          dateTo: "01/01/2021",
          position: "Software Engineer",
          experiences: [
            "Used React.js to build industry-leading enterprise applications",
            "Led projects improving customer satisfaction and retention",
            "Created new developer tools to aid in speed of software development",
            "Improved testing and alerting to keep availability at a maximum",
          ],
        },
        {
          dateFrom: "06/22/2015",
          dateTo: "06/15/2019",
          position: "Engineering Services Consultant",
          experiences: [
            "Used Angular.js to integrate with d3.js and create visualizations for analytics data",
            "Used PHP to retrieve data from analytics APIs and generate data for front-end visuals",
            "Worked as a consultant directly with customers to satisfy needs",
            "Used agile programming methods, and tools such as YouTrack",
            "Used technologies such as Angular.js, Sass, d3.js, PHP, mySQL, Apache, git, and Adobe Analytics",
          ],
        },
      ],
    },
    {
      dateFrom: "03/17/2014",
      dateTo: "06/17/2015",
      company: "Xactware Solutions",
      location: "Lehi, Ut",
      url: "https://xactware.com",
      logo: XW,
      positions: [
        {
          dateFrom: "03/17/2014",
          dateTo: "06/17/2015",
          position: "Software Engineer",
          experiences: [
            "Led development of Xactware's Xactimate Administration tool (XWAdmin)",
            "Rewrote Administration tool from .NET to using Angular.js with current web standards",
            "Used agile programming methods such as sprints, and tools such as JIRA",
            "Used technologies such as Angular.js, LESS, Lodash, Moment.js, Node.js, .NET, git, and subversion",
          ],
        },
      ],
    },
    {
      dateFrom: "September 2014",
      dateTo: "03/14/2014",
      company: "Vivint.Solar",
      location: "Provo, Ut",
      url: "https://vivintsolar.com",
      logo: VS,
      positions: [
        {
          dateFrom: "2013",
          dateTo: "03/14/2014",
          position: "Software Engineer Intern",
          experiences: [
            "Used Angular.js with other web technologies to implement sales and executive administration tools",
            "Used back end tools such as Node.js, CouchDB, and ElasticSearch to quickly and accurately display information to the users",
            "Used agile programming methods such as sprints, and tools such as Trello",
            "Used technologies such as Angular.js, d3.js, Moment.js, underscore.js, Node.js, CouchDB, ElasticSearch, Amazon Web Services, and git",
          ],
        },
      ],
    },
    {
      dateFrom: "01/01/2012",
      dateTo: "12/15/2013",
      company: "Utah State University",
      location: "Logan, Ut",
      url: "https://usu.edu",
      logo: USU,
      positions: [
        {
          dateFrom: "08/15/2013",
          dateTo: "12/15/2013",
          position: "Graduate Instructor",
          experiences: [
            "Taught Software Engineering Basics to Students",
            "Taught Students to use UML standards in Software Engineering",
            "Worked with Students to implement large-scale projects in a team",
            "Taught Students to use version control software such as git",
          ],
        },
        {
          dateFrom: "08/15/2012",
          dateTo: "12/31/2012",
          position: "Undergradutate Instructor",
          experiences: [
            "Taught Software Engineering Basics to Students",
            "Taught Students to use UML standards in Software Engineering",
            "Worked with Students to implement large-scale projects in a team",
            "Taught Students to use version control software such as git",
          ],
        },
        {
          dateFrom: "01/01/2012",
          dateTo: "05/15/2012",
          position: "Teacher's Assistant and Grader",
          experiences: [
            "Helped Students learn proper UML diagramming techniques",
            "Worked with Students to help them understand how to interact with customers",
            "Held office hours for Students to receive help on assignments",
          ],
        },
      ],
    },
    {
      dateFrom: "06/15/2012",
      dateTo: "08/15/2012",
      company: "RR Donnelley",
      location: "Logan, Ut",
      url: "https://rrd.com",
      logo: RRD,
      positions: [
        {
          dateFrom: "06/15/2012",
          dateTo: "08/15/2012",
          position: "Software Engineer Intern",
          experiences: [
            "Programmed in PERL, HP Exstream Dialogue, XML, and proprietary languages",
            "Worked with JCL, COBOL, Mainframe, and Unix",
            "Worked with Program Managers, sometimes over long distances, to guarantee product quality and on time delivery",
            "Selected as one of two interns from all applicants to participate in a new summer internship",
          ],
        },
      ],
    },
    {
      position: "Quality Assurance Specialist",
      dateFrom: "01/01/2004",
      dateTo: "08/15/2013",
      company: "Gallagher Bassett",
      location: "Kaysville, Ut",
      url: "https://www.gallagherbassett.com/technology-analytics/claimzone-reporter/",
      logo: GB,
      positions: [
        {
          dateFrom: "01/01/2004",
          dateTo: "08/15/2013",
          position: "Quality Assurance Specialist",
          experiences: [
            "Developed automated testing suites using Java and JUnit",
            "Tested software for usability, vulnerabilities, and general functionality",
            "Worked with a team to search for, and report issues found in software",
            "Verified issues to ensure an accurate and complete fix",
            "Used technologies such as Java, JUnit, Bugzilla, and subversion",
          ],
        },
      ],
    },
  ],
  achievements: [
    {
      achievement: "Proselyting Service Missionary",
      dateFrom: "08/15/2007",
      dateTo: "08/20/2009",
      organization: "The Church of Jesus Christ of Latter-Day Saints",
      location: "Oklahoma City, Ok",
      url: "https://www.churchofjesuschrist.org/comeuntochrist",
      logo: LDS,
      experiences: [
        "Led others to set and accomplish goals",
        "Worked 7 days per week as a full-time volunteer",
        "Released honorably after serving for 2 years",
      ],
    },
    {
      achievement: "Chapter Officer",
      dateFrom: "08/15/2005",
      dateTo: "06/15/2006",
      organization: "Future Business Leaders of America",
      location: "Kaysville, Ut",
      url: "https://fbla-pbl.org",
      logo: FBLA,
      experiences: [
        "Created and maintained the Chapter's website",
        "Organized and carried out school and chapter events",
        "Attended the National Future Business Leaders of America Leadership Conference",
      ],
    },
    // {
    //   achievement: "Eagle Scout",
    //   date: "08/31/2006",
    //   organization: "Boy Scouts of America",
    //   location: "Irving, Tx",
    //   url: "https://scouting.org",
    //   logo: BSA,
    //   experiences: [
    //     "Planned and carried out a project to supply soldiers in Afghanistan with hygiene kits to give to local civilians",
    //     "Led dozens of people in carrying out project",
    //   ],
    // },
    {
      achievement: "Mensa Member",
      date: "06/15/2011",
      organization: "American Mensa",
      location: "Arlington, Tx",
      url: "https://us.mensa.org",
      logo: Mensa,
      experiences: [],
    },
  ],
};

export default experience;

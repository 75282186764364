import React, { useEffect } from "react";

import ReactGA from "react-ga";

import Header from "./header/header";
import Objective from "./objective/objective";
import Education from "./education/education";
import Experience from "./experience/experience";
import Achievement from "./achievements/achievements";

import "./app.scss";

const trackingId = "G-DM61C31NMG";

function App() {
  useEffect(() => {
    ReactGA.initialize(trackingId);
    ReactGA.pageview("resume");
  }, []);

  return (
    <>
      <Header />
      <Objective />
      <Education />
      <Experience />
      <Achievement />
    </>
  );
}

export default App;

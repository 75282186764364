import React from "react";
import classNames from "classnames";

import { GlobeAltIcon, StarIcon } from "@heroicons/react/solid";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import data from "../../data";

dayjs.extend(localizedFormat);

export default function Example() {
  return (
    <div className="card max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow">
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Achievements
          </h3>
        </div>

        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          {data.achievements.map((achievement) => (
            <React.Fragment key={achievement.organization}>
              <div className="md:flex md:items-center md:justify-between md:space-x-5">
                <div className="flex items-start space-x-5">
                  <div className="flex-shrink-0">
                    <div className="relative">
                      <img
                        className="h-16 w-16 rounded-full"
                        src={achievement.logo}
                        alt=""
                      />
                      <span
                        className="absolute inset-0 shadow-inner rounded-full"
                        aria-hidden="true"
                      />
                    </div>
                  </div>

                  <div className="pt-1.5">
                    <h1 className="text-2xl font-bold text-gray-900">
                      {achievement.organization}
                    </h1>
                    <p className="text-sm font-medium text-gray-500">
                      {achievement.date
                        ? dayjs(achievement.date).format("LL")
                        : `${dayjs(achievement.dateFrom).format(
                            "LL"
                          )} - ${dayjs(achievement.dateTo).format("LL")}`}
                    </p>
                  </div>
                </div>
                <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                    onClick={() => window.open(`${achievement.url}`, "blank")}
                  >
                    <GlobeAltIcon
                      className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span>Website</span>
                  </button>
                </div>
              </div>
              {achievement.experiences.length ? (
                <div className="timeline flow-root">
                  <ul className="-mb-8">
                    <li>
                      <div className="relative pb-8">
                        <div className="relative flex space-x-3">
                          <div>
                            <span
                              className={classNames(
                                "bg-gray-400",
                                "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                              )}
                            >
                              <StarIcon
                                className="h-5 w-5 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                          <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                            <div className="min-w-0 flex-1">
                              <div>
                                <p className="text-sm text-gray-500">
                                  {`${achievement.achievement}`}
                                </p>
                              </div>
                              <div className="mt-2 text-sm text-gray-700">
                                <ul className="show-bullets">
                                  {achievement.experiences.map((experience) => (
                                    <li key={experience}>{experience}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              ) : null}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

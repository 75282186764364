import React from "react";

import data from "../../data";

export default function Example() {
  return (
    <div className="card max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow">
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Summary
          </h3>
        </div>

        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          {data.objective}
        </div>
      </div>
    </div>
  );
}
